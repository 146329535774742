

// Raster sprite (png, jpg, etc..)
@import "../../img/sprite/sprite"; 
// ......................................................

// $spritesheet-sprites: ($s-attention-symbol, $s-blue-mark, $s-calendar, $s-comment-white, $s-comment, $s-controls-slide, $s-correct-symbol, $s-eye, $s-key, $s-link, $s-mail, $s-more-link, $s-none-symbol, $s-okey, $s-person, $s-place, $s-quote, $s-red-mark, $s-search, $s-see, $s-sprite-icon, $s-subscriber, $s-under-line, $s-user-icon, $s-video-large, $s-video, $s-wrong-symbol, );

.test-sprite {
	position: relative;
	padding-left: 30px;
	&::before {
		content: "";
		display: inline-block;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		vertical-align: middle;
  		@include sprite($s-calendar);
	}
	&:hover {
		color: red;
		&::before {
	  		@include sprite($s-blue-mark);
		}
	}
}


// SVG-sprite (symbol)
@import "../../img/sprite/svg-sprite/_svg-sprite__symbol"; 
// ......................................................

.icon-svg {
	vertical-align: middle;

	&--bike {
		font-size: 50px;
		color: red;
	}
	&--tuxedo {
		font-size: 20px;
		color: blue;
	}
	&--facebook {
		font-size: 30px;
		color: aqua;
	}
}

// если svg-спрайт через css-bg --- полезна только с возможностью менять размеры, иначе это топорная вещь



// Icon Font Awesome
// ......................................................

.fa {
	color: inherit;
	// font-size: .7em;
	// vertical-align: middle;
}
