
@import "app--header";
@import "app--content";
@import "app--sidebar";
@import "app--footer";



.container {
	padding: 0;
	max-width: 1200px;
	// padding: 0 6%;
}



* {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}






