
.container {
	// border: 1px solid #000;
}

img[usemap],
map[name="image-map"] {
	-webkit-transform: scale(.82);
	   -moz-transform: scale(.82);
	    -ms-transform: scale(.82);
	     -o-transform: scale(.82);
	        transform: scale(.82);
	transform-origin: 0% 0px;
}

// [name="image-map"] {
// 	max-width: 100%;
// }

.nav-map {
	margin-top: 50px;
	a {
		display: block;
		text-decoration: none;
		&:hover {
			text-decoration: none;
		}
	}
	[el] {
		font-size: 17px;
		line-height: 17px;
		color: #000;
		min-height: 36px;
		margin-bottom: 5px;
		vertical-align: top;
		&[status="current"],
		&:hover {
			position: relative;
		    color: #a9482c;
		    font-weight: bold;
		    font-size: 20px;
		}
	}
}