@charset "UTF-8";
/* 
* Custom
*/
html, body {
  font-family: "", sans-serif;
  font-size: 16px;
  line-height: 1.75; }

.test-sprite {
  position: relative;
  padding-left: 30px; }
  .test-sprite::before {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: middle;
    background-image: url(/img/sprite/sprite.png);
    background-position: 0px -89px;
    width: 18px;
    height: 18px; }
  .test-sprite:hover {
    color: red; }
    .test-sprite:hover::before {
      background-image: url(/img/sprite/sprite.png);
      background-position: 0px -109px;
      width: 11px;
      height: 18px; }

.icon-svg {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor; }

.icon-svg--bike {
  font-size: 3.2rem;
  width: 1em; }

.icon-svg--facebook {
  font-size: 2rem;
  width: 1em; }

.icon-svg--polo-shirt {
  font-size: 3.2rem;
  width: 1em; }

.icon-svg--tuxedo {
  font-size: 3.2rem;
  width: 1em; }

.icon-svg {
  vertical-align: middle; }
  .icon-svg--bike {
    font-size: 50px;
    color: red; }
  .icon-svg--tuxedo {
    font-size: 20px;
    color: blue; }
  .icon-svg--facebook {
    font-size: 30px;
    color: aqua; }

.fa {
  color: inherit; }

.header {
  display: block; }

img[usemap],
map[name="image-map"] {
  -webkit-transform: scale(0.82);
  -moz-transform: scale(0.82);
  -ms-transform: scale(0.82);
  -o-transform: scale(0.82);
  transform: scale(0.82);
  transform-origin: 0% 0px; }

.nav-map {
  margin-top: 50px; }
  .nav-map a {
    display: block;
    text-decoration: none; }
    .nav-map a:hover {
      text-decoration: none; }
  .nav-map [el] {
    font-size: 17px;
    line-height: 17px;
    color: #000;
    min-height: 36px;
    margin-bottom: 5px;
    vertical-align: top; }
    .nav-map [el][status="current"], .nav-map [el]:hover {
      position: relative;
      color: #a9482c;
      font-weight: bold;
      font-size: 20px; }

.container {
  padding: 0;
  max-width: 1200px; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/*==========  Desktop First  ==========*/
/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
  /**/
  img[usemap],
  map[name="image-map"] {
    -webkit-transform: scale(0.68);
    -moz-transform: scale(0.68);
    -ms-transform: scale(0.68);
    -o-transform: scale(0.68);
    transform: scale(0.68); } }

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  /**/
  img[usemap],
  map[name="image-map"] {
    -webkit-transform: scale(0.58);
    -moz-transform: scale(0.58);
    -ms-transform: scale(0.58);
    -o-transform: scale(0.58);
    transform: scale(0.58); } }

@media only screen and (max-width: 860px) {
  img[usemap],
  map[name="image-map"] {
    -webkit-transform: scale(0.48);
    -moz-transform: scale(0.48);
    -ms-transform: scale(0.48);
    -o-transform: scale(0.48);
    transform: scale(0.48); } }

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .navbar-offcanvas {
    background: #FFF; }
  /**/
  img[usemap],
  map[name="image-map"] {
    -webkit-transform: scale(0.38);
    -moz-transform: scale(0.38);
    -ms-transform: scale(0.38);
    -o-transform: scale(0.38);
    transform: scale(0.38); } }

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  /**/ }

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
  /**/ }

/*==========  Mobile First  ==========*/
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
  /**/ }

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
  /**/ }

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  /**/ }

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  /**/ }

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  /**/ }

.nav-map [status="current"] {
  -webkit-transition: all .2s linear;
  -moz-transition: all .2s linear;
  -ms-transition: all .2s linear;
  -o-transition: all .2s linear;
  transition: all .2s linear; }

.animate-simple {
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -ms-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease; }

.key-animation {
  -webkit-animation: NameAnimation 5s infinite;
  -moz-animation: NameAnimation 5s infinite;
  -o-animation: NameAnimation 5s infinite;
  animation: NameAnimation 5s infinite; }

@-webkit-keyframes NameAnimation {
  100% { } }

@keyframes NameAnimation {
  100% { } }

/* стили для IE10 и выше */
body {
  position: relative;
  min-width: 320px;
  max-width: 100%;
  overflow-x: hidden; }

*::-webkit-input-placeholder {
  color: #666;
  opacity: 1; }

*:-moz-placeholder {
  color: #666;
  opacity: 1; }

*::-moz-placeholder {
  color: #666;
  opacity: 1; }

*:-ms-input-placeholder {
  color: #666;
  opacity: 1; }
