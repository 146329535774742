

@media screen and (min-width: 100px) {
}

/*==========  Desktop First  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
	/**/
	img[usemap],
	map[name="image-map"] {
		-webkit-transform: scale(.68);
		   -moz-transform: scale(.68);
		    -ms-transform: scale(.68);
		     -o-transform: scale(.68);
		        transform: scale(.68);
	}

}


/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
	/**/
	img[usemap],
	map[name="image-map"] {
		-webkit-transform: scale(.58);
		   -moz-transform: scale(.58);
		    -ms-transform: scale(.58);
		     -o-transform: scale(.58);
		        transform: scale(.58);
	}
}

@media only screen and (max-width : 860px) {
	img[usemap],
	map[name="image-map"] {
		-webkit-transform: scale(.48);
		   -moz-transform: scale(.48);
		    -ms-transform: scale(.48);
		     -o-transform: scale(.48);
		        transform: scale(.48);
	}
}


/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
	// bt4 - offcanvas menu
	.navbar-offcanvas {background: #FFF;} 
	/**/
	img[usemap],
	map[name="image-map"] {
		-webkit-transform: scale(.38);
		   -moz-transform: scale(.38);
		    -ms-transform: scale(.38);
		     -o-transform: scale(.38);
		        transform: scale(.38);
	}
}



/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
	/**/
}


/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {
	/**/
}


/*==========  Mobile First  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
	/**/
}


/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
	/**/
}


/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	/**/
}


/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	/**/
}


/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	/**/}
