

.nav-map [status="current"] {
	-webkit-transition: all .2s linear;
	   -moz-transition: all .2s linear;
	    -ms-transition: all .2s linear;
	     -o-transition: all .2s linear;
	        transition: all .2s linear;
}

// single animation

.animate-simple {
	-webkit-transition: all .5s ease;
	   -moz-transition: all .5s ease;
	    -ms-transition: all .5s ease;
	     -o-transition: all .5s ease;
	        transition: all .5s ease;
}



// multiple animation

.key-animation {
	-webkit-animation: NameAnimation 5s infinite;
	   -moz-animation: NameAnimation 5s infinite;
	     -o-animation: NameAnimation 5s infinite;
	        animation: NameAnimation 5s infinite;
}

@-webkit-keyframes NameAnimation {
  100% {
  }
}
@keyframes NameAnimation {
  100% {
  }
}