// $icons: (
// 	  sprite: (width: px, height: px, svgPath: '../img/sprite.svg'),
//  	svg--bike: (width: 32px, height: 32px, backgroundX: px, backgroundY: px),
//  	svg--facebook: (width: 20px, height: 20px, backgroundX: px, backgroundY: px),
//  	svg--polo-shirt: (width: 32px, height: 32px, backgroundX: px, backgroundY: px),
//  	svg--tuxedo: (width: 32px, height: 32px, backgroundX: px, backgroundY: px),
// );

.icon-svg {
	display: inline-block;
	width: 1em;
	height: 1em;
	fill: currentColor;
}

.icon-svg--bike {
	font-size:(32/10)*1rem;
	width:(32/32)*1em;
}
.icon-svg--facebook {
	font-size:(20/10)*1rem;
	width:(20/20)*1em;
}
.icon-svg--polo-shirt {
	font-size:(32/10)*1rem;
	width:(32/32)*1em;
}
.icon-svg--tuxedo {
	font-size:(32/10)*1rem;
	width:(32/32)*1em;
}
