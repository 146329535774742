// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$s-attention-symbol-name: 's-attention-symbol';
$s-attention-symbol-x: 0px;
$s-attention-symbol-y: 233px;
$s-attention-symbol-offset-x: 0px;
$s-attention-symbol-offset-y: -233px;
$s-attention-symbol-width: 20px;
$s-attention-symbol-height: 20px;
$s-attention-symbol-total-width: 450px;
$s-attention-symbol-total-height: 1037px;
$s-attention-symbol-image: '/img/sprite/sprite.png';
$s-attention-symbol: (0px, 233px, 0px, -233px, 20px, 20px, 450px, 1037px, '/img/sprite/sprite.png', 's-attention-symbol', );
$s-blue-mark-name: 's-blue-mark';
$s-blue-mark-x: 0px;
$s-blue-mark-y: 109px;
$s-blue-mark-offset-x: 0px;
$s-blue-mark-offset-y: -109px;
$s-blue-mark-width: 11px;
$s-blue-mark-height: 18px;
$s-blue-mark-total-width: 450px;
$s-blue-mark-total-height: 1037px;
$s-blue-mark-image: '/img/sprite/sprite.png';
$s-blue-mark: (0px, 109px, 0px, -109px, 11px, 18px, 450px, 1037px, '/img/sprite/sprite.png', 's-blue-mark', );
$s-calendar-name: 's-calendar';
$s-calendar-x: 0px;
$s-calendar-y: 89px;
$s-calendar-offset-x: 0px;
$s-calendar-offset-y: -89px;
$s-calendar-width: 18px;
$s-calendar-height: 18px;
$s-calendar-total-width: 450px;
$s-calendar-total-height: 1037px;
$s-calendar-image: '/img/sprite/sprite.png';
$s-calendar: (0px, 89px, 0px, -89px, 18px, 18px, 450px, 1037px, '/img/sprite/sprite.png', 's-calendar', );
$s-comment-white-name: 's-comment-white';
$s-comment-white-x: 0px;
$s-comment-white-y: 149px;
$s-comment-white-offset-x: 0px;
$s-comment-white-offset-y: -149px;
$s-comment-white-width: 18px;
$s-comment-white-height: 18px;
$s-comment-white-total-width: 450px;
$s-comment-white-total-height: 1037px;
$s-comment-white-image: '/img/sprite/sprite.png';
$s-comment-white: (0px, 149px, 0px, -149px, 18px, 18px, 450px, 1037px, '/img/sprite/sprite.png', 's-comment-white', );
$s-comment-name: 's-comment';
$s-comment-x: 0px;
$s-comment-y: 129px;
$s-comment-offset-x: 0px;
$s-comment-offset-y: -129px;
$s-comment-width: 18px;
$s-comment-height: 18px;
$s-comment-total-width: 450px;
$s-comment-total-height: 1037px;
$s-comment-image: '/img/sprite/sprite.png';
$s-comment: (0px, 129px, 0px, -129px, 18px, 18px, 450px, 1037px, '/img/sprite/sprite.png', 's-comment', );
$s-controls-slide-name: 's-controls-slide';
$s-controls-slide-x: 0px;
$s-controls-slide-y: 503px;
$s-controls-slide-offset-x: 0px;
$s-controls-slide-offset-y: -503px;
$s-controls-slide-width: 59px;
$s-controls-slide-height: 59px;
$s-controls-slide-total-width: 450px;
$s-controls-slide-total-height: 1037px;
$s-controls-slide-image: '/img/sprite/sprite.png';
$s-controls-slide: (0px, 503px, 0px, -503px, 59px, 59px, 450px, 1037px, '/img/sprite/sprite.png', 's-controls-slide', );
$s-correct-symbol-name: 's-correct-symbol';
$s-correct-symbol-x: 0px;
$s-correct-symbol-y: 343px;
$s-correct-symbol-offset-x: 0px;
$s-correct-symbol-offset-y: -343px;
$s-correct-symbol-width: 20px;
$s-correct-symbol-height: 20px;
$s-correct-symbol-total-width: 450px;
$s-correct-symbol-total-height: 1037px;
$s-correct-symbol-image: '/img/sprite/sprite.png';
$s-correct-symbol: (0px, 343px, 0px, -343px, 20px, 20px, 450px, 1037px, '/img/sprite/sprite.png', 's-correct-symbol', );
$s-eye-name: 's-eye';
$s-eye-x: 0px;
$s-eye-y: 0px;
$s-eye-offset-x: 0px;
$s-eye-offset-y: 0px;
$s-eye-width: 18px;
$s-eye-height: 10px;
$s-eye-total-width: 450px;
$s-eye-total-height: 1037px;
$s-eye-image: '/img/sprite/sprite.png';
$s-eye: (0px, 0px, 0px, 0px, 18px, 10px, 450px, 1037px, '/img/sprite/sprite.png', 's-eye', );
$s-key-name: 's-key';
$s-key-x: 0px;
$s-key-y: 299px;
$s-key-offset-x: 0px;
$s-key-offset-y: -299px;
$s-key-width: 20px;
$s-key-height: 20px;
$s-key-total-width: 450px;
$s-key-total-height: 1037px;
$s-key-image: '/img/sprite/sprite.png';
$s-key: (0px, 299px, 0px, -299px, 20px, 20px, 450px, 1037px, '/img/sprite/sprite.png', 's-key', );
$s-link-name: 's-link';
$s-link-x: 0px;
$s-link-y: 12px;
$s-link-offset-x: 0px;
$s-link-offset-y: -12px;
$s-link-width: 20px;
$s-link-height: 11px;
$s-link-total-width: 450px;
$s-link-total-height: 1037px;
$s-link-image: '/img/sprite/sprite.png';
$s-link: (0px, 12px, 0px, -12px, 20px, 11px, 450px, 1037px, '/img/sprite/sprite.png', 's-link', );
$s-mail-name: 's-mail';
$s-mail-x: 0px;
$s-mail-y: 53px;
$s-mail-offset-x: 0px;
$s-mail-offset-y: -53px;
$s-mail-width: 20px;
$s-mail-height: 15px;
$s-mail-total-width: 450px;
$s-mail-total-height: 1037px;
$s-mail-image: '/img/sprite/sprite.png';
$s-mail: (0px, 53px, 0px, -53px, 20px, 15px, 450px, 1037px, '/img/sprite/sprite.png', 's-mail', );
$s-more-link-name: 's-more-link';
$s-more-link-x: 0px;
$s-more-link-y: 365px;
$s-more-link-offset-x: 0px;
$s-more-link-offset-y: -365px;
$s-more-link-width: 20px;
$s-more-link-height: 20px;
$s-more-link-total-width: 450px;
$s-more-link-total-height: 1037px;
$s-more-link-image: '/img/sprite/sprite.png';
$s-more-link: (0px, 365px, 0px, -365px, 20px, 20px, 450px, 1037px, '/img/sprite/sprite.png', 's-more-link', );
$s-none-symbol-name: 's-none-symbol';
$s-none-symbol-x: 0px;
$s-none-symbol-y: 211px;
$s-none-symbol-offset-x: 0px;
$s-none-symbol-offset-y: -211px;
$s-none-symbol-width: 20px;
$s-none-symbol-height: 20px;
$s-none-symbol-total-width: 450px;
$s-none-symbol-total-height: 1037px;
$s-none-symbol-image: '/img/sprite/sprite.png';
$s-none-symbol: (0px, 211px, 0px, -211px, 20px, 20px, 450px, 1037px, '/img/sprite/sprite.png', 's-none-symbol', );
$s-okey-name: 's-okey';
$s-okey-x: 0px;
$s-okey-y: 189px;
$s-okey-offset-x: 0px;
$s-okey-offset-y: -189px;
$s-okey-width: 20px;
$s-okey-height: 20px;
$s-okey-total-width: 450px;
$s-okey-total-height: 1037px;
$s-okey-image: '/img/sprite/sprite.png';
$s-okey: (0px, 189px, 0px, -189px, 20px, 20px, 450px, 1037px, '/img/sprite/sprite.png', 's-okey', );
$s-person-name: 's-person';
$s-person-x: 0px;
$s-person-y: 255px;
$s-person-offset-x: 0px;
$s-person-offset-y: -255px;
$s-person-width: 15px;
$s-person-height: 20px;
$s-person-total-width: 450px;
$s-person-total-height: 1037px;
$s-person-image: '/img/sprite/sprite.png';
$s-person: (0px, 255px, 0px, -255px, 15px, 20px, 450px, 1037px, '/img/sprite/sprite.png', 's-person', );
$s-place-name: 's-place';
$s-place-x: 0px;
$s-place-y: 277px;
$s-place-offset-x: 0px;
$s-place-offset-y: -277px;
$s-place-width: 16px;
$s-place-height: 20px;
$s-place-total-width: 450px;
$s-place-total-height: 1037px;
$s-place-image: '/img/sprite/sprite.png';
$s-place: (0px, 277px, 0px, -277px, 16px, 20px, 450px, 1037px, '/img/sprite/sprite.png', 's-place', );
$s-quote-name: 's-quote';
$s-quote-x: 0px;
$s-quote-y: 409px;
$s-quote-offset-x: 0px;
$s-quote-offset-y: -409px;
$s-quote-width: 40px;
$s-quote-height: 36px;
$s-quote-total-width: 450px;
$s-quote-total-height: 1037px;
$s-quote-image: '/img/sprite/sprite.png';
$s-quote: (0px, 409px, 0px, -409px, 40px, 36px, 450px, 1037px, '/img/sprite/sprite.png', 's-quote', );
$s-red-mark-name: 's-red-mark';
$s-red-mark-x: 0px;
$s-red-mark-y: 25px;
$s-red-mark-offset-x: 0px;
$s-red-mark-offset-y: -25px;
$s-red-mark-width: 12px;
$s-red-mark-height: 12px;
$s-red-mark-total-width: 450px;
$s-red-mark-total-height: 1037px;
$s-red-mark-image: '/img/sprite/sprite.png';
$s-red-mark: (0px, 25px, 0px, -25px, 12px, 12px, 450px, 1037px, '/img/sprite/sprite.png', 's-red-mark', );
$s-search-name: 's-search';
$s-search-x: 0px;
$s-search-y: 564px;
$s-search-offset-x: 0px;
$s-search-offset-y: -564px;
$s-search-width: 20px;
$s-search-height: 70px;
$s-search-total-width: 450px;
$s-search-total-height: 1037px;
$s-search-image: '/img/sprite/sprite.png';
$s-search: (0px, 564px, 0px, -564px, 20px, 70px, 450px, 1037px, '/img/sprite/sprite.png', 's-search', );
$s-see-name: 's-see';
$s-see-x: 0px;
$s-see-y: 70px;
$s-see-offset-x: 0px;
$s-see-offset-y: -70px;
$s-see-width: 20px;
$s-see-height: 17px;
$s-see-total-width: 450px;
$s-see-total-height: 1037px;
$s-see-image: '/img/sprite/sprite.png';
$s-see: (0px, 70px, 0px, -70px, 20px, 17px, 450px, 1037px, '/img/sprite/sprite.png', 's-see', );
$s-sprite-icon-name: 's-sprite-icon';
$s-sprite-icon-x: 0px;
$s-sprite-icon-y: 737px;
$s-sprite-icon-offset-x: 0px;
$s-sprite-icon-offset-y: -737px;
$s-sprite-icon-width: 300px;
$s-sprite-icon-height: 300px;
$s-sprite-icon-total-width: 450px;
$s-sprite-icon-total-height: 1037px;
$s-sprite-icon-image: '/img/sprite/sprite.png';
$s-sprite-icon: (0px, 737px, 0px, -737px, 300px, 300px, 450px, 1037px, '/img/sprite/sprite.png', 's-sprite-icon', );
$s-subscriber-name: 's-subscriber';
$s-subscriber-x: 0px;
$s-subscriber-y: 169px;
$s-subscriber-offset-x: 0px;
$s-subscriber-offset-y: -169px;
$s-subscriber-width: 20px;
$s-subscriber-height: 18px;
$s-subscriber-total-width: 450px;
$s-subscriber-total-height: 1037px;
$s-subscriber-image: '/img/sprite/sprite.png';
$s-subscriber: (0px, 169px, 0px, -169px, 20px, 18px, 450px, 1037px, '/img/sprite/sprite.png', 's-subscriber', );
$s-under-line-name: 's-under-line';
$s-under-line-x: 0px;
$s-under-line-y: 39px;
$s-under-line-offset-x: 0px;
$s-under-line-offset-y: -39px;
$s-under-line-width: 450px;
$s-under-line-height: 12px;
$s-under-line-total-width: 450px;
$s-under-line-total-height: 1037px;
$s-under-line-image: '/img/sprite/sprite.png';
$s-under-line: (0px, 39px, 0px, -39px, 450px, 12px, 450px, 1037px, '/img/sprite/sprite.png', 's-under-line', );
$s-user-icon-name: 's-user-icon';
$s-user-icon-x: 0px;
$s-user-icon-y: 321px;
$s-user-icon-offset-x: 0px;
$s-user-icon-offset-y: -321px;
$s-user-icon-width: 16px;
$s-user-icon-height: 20px;
$s-user-icon-total-width: 450px;
$s-user-icon-total-height: 1037px;
$s-user-icon-image: '/img/sprite/sprite.png';
$s-user-icon: (0px, 321px, 0px, -321px, 16px, 20px, 450px, 1037px, '/img/sprite/sprite.png', 's-user-icon', );
$s-video-large-name: 's-video-large';
$s-video-large-x: 0px;
$s-video-large-y: 636px;
$s-video-large-offset-x: 0px;
$s-video-large-offset-y: -636px;
$s-video-large-width: 99px;
$s-video-large-height: 99px;
$s-video-large-total-width: 450px;
$s-video-large-total-height: 1037px;
$s-video-large-image: '/img/sprite/sprite.png';
$s-video-large: (0px, 636px, 0px, -636px, 99px, 99px, 450px, 1037px, '/img/sprite/sprite.png', 's-video-large', );
$s-video-name: 's-video';
$s-video-x: 0px;
$s-video-y: 447px;
$s-video-offset-x: 0px;
$s-video-offset-y: -447px;
$s-video-width: 54px;
$s-video-height: 54px;
$s-video-total-width: 450px;
$s-video-total-height: 1037px;
$s-video-image: '/img/sprite/sprite.png';
$s-video: (0px, 447px, 0px, -447px, 54px, 54px, 450px, 1037px, '/img/sprite/sprite.png', 's-video', );
$s-wrong-symbol-name: 's-wrong-symbol';
$s-wrong-symbol-x: 0px;
$s-wrong-symbol-y: 387px;
$s-wrong-symbol-offset-x: 0px;
$s-wrong-symbol-offset-y: -387px;
$s-wrong-symbol-width: 20px;
$s-wrong-symbol-height: 20px;
$s-wrong-symbol-total-width: 450px;
$s-wrong-symbol-total-height: 1037px;
$s-wrong-symbol-image: '/img/sprite/sprite.png';
$s-wrong-symbol: (0px, 387px, 0px, -387px, 20px, 20px, 450px, 1037px, '/img/sprite/sprite.png', 's-wrong-symbol', );
$spritesheet-width: 450px;
$spritesheet-height: 1037px;
$spritesheet-image: '/img/sprite/sprite.png';
$spritesheet-sprites: ($s-attention-symbol, $s-blue-mark, $s-calendar, $s-comment-white, $s-comment, $s-controls-slide, $s-correct-symbol, $s-eye, $s-key, $s-link, $s-mail, $s-more-link, $s-none-symbol, $s-okey, $s-person, $s-place, $s-quote, $s-red-mark, $s-search, $s-see, $s-sprite-icon, $s-subscriber, $s-under-line, $s-user-icon, $s-video-large, $s-video, $s-wrong-symbol, );
$spritesheet: (450px, 1037px, '/img/sprite/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
