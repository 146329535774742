@import "partials/libs-develop";
/* 
* Custom
*/
@import "partials/vars";
@import "partials/fonts";
@import "partials/project-sprite";

@import "partials/app";
// @import "partials/app__grid";
@import "partials/media";

@import "partials/css-animate";
@import "partials/IEfix";

// Global 
body {
	position: relative;
	min-width: 320px;
	max-width: 100%;
	overflow-x: hidden;
}
	
*::-webkit-input-placeholder {
	color: #666;
	opacity: 1
}

*:-moz-placeholder {
	color: #666;
	opacity: 1
}

*::-moz-placeholder {
	color: #666;
	opacity: 1
}

*:-ms-input-placeholder {
	color: #666;
	opacity: 1
}


	
